import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import { RoleEnum } from "../utils/types";

import HomeView from "../views/HomeView.vue";
import Error404View from "../views/Error404View.vue";
import QuestionnaireAlreadyCompletedView from "../views/QuestionnaireAlreadyCompletedView.vue";
import LoginView from "../views/LoginView.vue";
import PasswordResetView from "../views/PasswordResetView.vue";
import ImprintView from "../views/ImprintView.vue";
import DataProtectionView from "../views/DataProtectionView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/imprint",
    name: "Imprint",
    component: ImprintView,
  },
  {
    path: "/data-protection",
    name: "DataProtection",
    component: DataProtectionView,
  },
  {
    path: "/password-reset/:token?",
    name: "PasswordReset",
    component: PasswordResetView,
  },
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: {
      requiredRoles: [
        RoleEnum.ACCOMMODATION_ADMIN,
        RoleEnum.ACCOMMODATION_USER,
      ],
    },
  },
  {
    path: "/users",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/UsersView.vue"),
    meta: {
      requiredRoles: [RoleEnum.APPLICATION_ADMIN, RoleEnum.ACCOMMODATION_ADMIN],
    },
  },
  {
    path: "/accommodations",
    name: "Accommodations",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/AccommodationsView.vue"),
    meta: {
      requiredRoles: [RoleEnum.APPLICATION_ADMIN],
    },
  },
  {
    path: "/dashboard/:dashboardId",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/DashboardView.vue"),
    meta: {
      requiredRoles: [
        RoleEnum.ACCOMMODATION_ADMIN,
        RoleEnum.ACCOMMODATION_USER,
      ],
    },
  },
  {
    path: "/questionnaire-invitations/",
    name: "QuestionnaireInvitations",
    component: () =>
      import(
        /* webpackChunkName: "users" */ "../views/QuestionnaireInvitationsView.vue"
      ),
    meta: {
      requiredRoles: [RoleEnum.ACCOMMODATION_ADMIN],
    },
  },
  {
    path: "/surveys/:token",
    name: "InvitationResponses",
    component: () =>
      import(
        /* webpackChunkName: "surveys" */ "../views/InvitationResponsesView.vue"
      ),
  },
  {
    path: "/surveys/preview/:id",
    name: "Preview",
    component: () =>
      import(
        /* webpackChunkName: "users" */ "../views/InvitationResponsesView.vue"
      ),
    meta: {
      requiredRoles: [RoleEnum.APPLICATION_ADMIN, RoleEnum.ACCOMMODATION_ADMIN],
    },
  },
  {
    path: "/questionnaires",
    name: "Questionnaires",
    component: () =>
      import(
        /* webpackChunkName: "questionnaires" */ "../views/QuestionnairesView.vue"
      ),
    meta: {
      requiredRoles: [RoleEnum.APPLICATION_ADMIN],
    },
    children: [
      {
        path: "new",
        name: "QuestionnaireCreate",
        component: () =>
          import(
            /* webpackChunkName: "questionnaires"*/ "../views/QuestionnaireCreateView.vue"
          ),
        meta: {
          requiredRoles: [RoleEnum.APPLICATION_ADMIN],
        },
      },
      {
        path: ":questionnaireId",
        name: "Questionnaire",
        component: () =>
          import(
            /* webpackChunkName: "questionnaires"*/ "../views/QuestionnaireView.vue"
          ),
        meta: {
          requiredRoles: [RoleEnum.APPLICATION_ADMIN],
        },
        children: [
          {
            path: "update",
            name: "QuestionnaireUpdate",
            component: () =>
              import(
                /* webpackChunkName: "questionnaires"*/ "../views/QuestionnaireUpdateView.vue"
              ),
            meta: {
              requiredRoles: [RoleEnum.APPLICATION_ADMIN],
            },
          },
          {
            path: "questions/:sourceQuestionId",
            name: "QuestionnaireQuestion",
            component: () =>
              import(
                /* webpackChunkName: "questionnaires"*/ "../views/QuestionnaireQuestionView.vue"
              ),
            meta: {
              requiredRoles: [RoleEnum.APPLICATION_ADMIN],
            },
          },
          {
            path: "question/new",
            name: "QuestionnaireQuestionCreate",
            component: () =>
              import(
                /* webpackChunkName: "questionnaires"*/ "../views/QuestionnaireQuestionCreateView.vue"
              ),
            meta: {
              requiredRoles: [RoleEnum.APPLICATION_ADMIN],
            },
          },
        ],
      },
    ],
  },
  {
    path: "/dashboards",
    name: "Dashboards",
    component: () =>
      import(
        /* webpackChunkName: "dashboards" */ "../views/DashboardsView.vue"
      ),
    meta: {
      requiredRoles: [RoleEnum.APPLICATION_ADMIN],
    },
    children: [
      {
        path: "new",
        name: "DashboardCreate",
        component: () =>
          import(
            /* webpackChunkName: "dasboards"*/ "../views/DashboardCreateView.vue"
          ),
        meta: {
          requiredRoles: [RoleEnum.APPLICATION_ADMIN],
        },
      },
      {
        path: ":dashboardId",
        name: "DashboardDetails",
        component: () =>
          import(
            /* webpackChunkName: "dasboards"*/ "../views/DashboardDetailsView.vue"
          ),
        meta: {
          requiredRoles: [RoleEnum.APPLICATION_ADMIN],
        },
        children: [
          {
            path: "update",
            name: "DashboardUpdate",
            component: () =>
              import(
                /* webpackChunkName: "dasboards"*/ "../views/DashboardUpdateView.vue"
              ),
            meta: {
              requiredRoles: [RoleEnum.APPLICATION_ADMIN],
            },
          },
          {
            path: "visualizations/:visualizationId",
            name: "DashboardVisualization",
            component: () =>
              import(
                /* webpackChunkName: "dasboards"*/ "../views/DashboardVisualizationView.vue"
              ),
            meta: {
              requiredRoles: [RoleEnum.APPLICATION_ADMIN],
            },
          },
          {
            path: "visualization/new",
            name: "DashboardVisualizationCreate",
            component: () =>
              import(
                /* webpackChunkName: "dasboards"*/ "../views/DashboardVisualizationCreateView.vue"
              ),
            meta: {
              requiredRoles: [RoleEnum.APPLICATION_ADMIN],
            },
          },
        ],
      },
    ],
  },
  {
    path: "/surveys/completed",
    name: "ThankYou",
    component: () =>
      import(/* webpackChunkName: "surveys" */ "../views/ThankYouView.vue"),
  },
  {
    path: "/export",
    name: "Export",
    component: () =>
      import(/* webpackChunkName: "export" */ "../views/ExportView.vue"),
    meta: {
      requiredRoles: [RoleEnum.ACCOMMODATION_ADMIN],
    },
  },
  {
    path: "/already-completed",
    name: "QuestionnaireAlreadyCompleted",
    component: QuestionnaireAlreadyCompletedView,
  },
  {
    path: "*",
    name: "Error404",
    component: Error404View,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const { requiredRoles } = to.meta;

  if (!requiredRoles) {
    return next();
  }

  try {
    await store.dispatch("authentication/refresh");
  } catch {
    return next({ name: "Login", query: { session: 1 } });
  }

  if (!store.getters["authentication/getIsAuthenticated"]) {
    return next({ name: "Login", query: { session: 1 } });
  }

  const role = store.getters["authentication/getRole"];
  const isAuthorized = !!requiredRoles && requiredRoles.includes(role);
  if (isAuthorized) {
    return next();
  } else {
    if (role === RoleEnum.APPLICATION_ADMIN) {
      return next({ name: "Users" });
    } else {
      return next({ name: "Home" });
    }
  }
});

export default router;
