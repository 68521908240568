import $axios from "../../plugins/axios";

const state = {
  isAuthenticated: false,
  accessToken: null,
  role: null,
  me: null,
};

const getters = {
  getIsAuthenticated: (state) => state.isAuthenticated,
  getAccessToken: (state) => state.accessToken,
  getRole: (state) => state.role,
  getMe: (state) => state.me,
};

const mutations = {
  commitIsAuthenticated: (state, isAuthenticated) => {
    state.isAuthenticated = isAuthenticated;
  },
  commitAccessToken: (state, accessToken) => {
    state.accessToken = accessToken;
  },
  commitRole: (state, role) => {
    state.role = role;
  },
  commitMe: (state, me) => {
    state.me = me;
  },
};

const actions = {
  fetchMe: async (context) => {
    const response = await $axios.get("/auth/me");
    context.commit("commitMe", response.data);
    context.commit("commitRole", response.data.role);
  },
  loginUserPassword: async (context, { username, password }) => {
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);
    let response = await $axios.post("/auth/login", params);
    context.commit("commitAccessToken", response.data);
  },
  loginTwoFactor: async (context, { totp }) => {
    await $axios.post(
      "/auth/2fa-challenge",
      {
        verification_code: totp,
      },
      {
        headers: {
          Authorization: `Bearer ${context.state.accessToken}`,
        },
      }
    );
    context.commit("commitAccessToken", null);
    context.commit("commitIsAuthenticated", true);
    await context.dispatch("refresh");
  },
  refresh: async (context) => {
    await context.dispatch("fetchMe");
    context.commit("commitIsAuthenticated", true);
  },
  logout: async (context) => {
    await $axios.post("/auth/logout");
    context.commit("reset", null, { root: true });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
