<template>
  <div class="primary lighten-5">
    <v-alert v-if="!!$route.query.session" type="warning" tile dismissible>
      Ihre Session ist abgelaufen. Bitte melden Sie sich erneut an.
    </v-alert>
    <v-container>
      <v-row
        class="justify-center align-center"
        style="min-height: calc(100vh - 124px)"
      >
        <v-col cols="12" md="5" lg="4" xl="3">
          <v-slide-x-transition mode="out-in">
            <user-password-card
              v-if="!is2FaVisible"
              @success="is2FaVisible = true"
              key="basic"
            >
            </user-password-card>
            <two-factor-card v-else key="2fa"> </two-factor-card>
          </v-slide-x-transition>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TwoFactorCard from "../components/login/TwoFactorCard.vue";
import UserPasswordCard from "../components/login/UserPasswordCard.vue";
export default {
  components: { UserPasswordCard, TwoFactorCard },
  name: "ViewLogin",
  data: () => ({
    is2FaVisible: false,
  }),
};
</script>
