import axios from "axios";
import store from "../store";
import router from "../router";

const $axios = axios.create({
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "x-csrf-token",
  withCredentials: true,
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000,
  withXSRFToken: true,
});

$axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response?.status !== 401) {
      throw error;
    }
    store.commit("reset");
    router.push({
      name: "Login",
      query: {
        session: 0,
      },
    });
    return error;
  }
);

export default $axios;
